export default {
  created() {
    const currentUrl = new URL(window.location.href);
    const lang = currentUrl.searchParams.get('lang');
    if (lang) this.setLang(lang);
  },

  methods: {
    setLang(lang) {
      this.$i18n.locale = lang || this.$store.state.default_locale || 'en';
      this.updateRTL();
      this.updateHtmlLang();
    },

    updateHtmlLang() {
      document.documentElement.lang = this.$i18n.locale;
    },

    updateRTL() {
      this.$vuetify.rtl = this.$a.assets.rightToLeftLanguages.includes(this.$i18n.locale);
    },
  },

  watch: {
    '$store.state.default_locale': {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.$i18n.locale) {
          this.setLang(newVal);
        }
      },
    },
  },
};
