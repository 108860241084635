/* eslint-disable no-param-reassign */
import { getTranslatedText } from '@/shared/utils/translations';
import i18n from '@/plugins/i18n';

const translate = async (el, binding) => {
  const inputText = binding.value;
  const translated = await getTranslatedText(inputText, i18n.global);
  el.innerHTML = translated;
};

export default translate;
