<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="mx-auto mxw-800">
    <v-btn
      v-if="$store.state.profile"
      :ripple="false"
      :to="{ name: 'Dashboard' }"
      class="bc-body-sm px-0 mt-3"
      variant="text"
      tracked
    >
      <v-icon class="fs-24"> keyboard_backspace </v-icon>
      <span class="ms-2">{{ $t('Back') }}</span>
    </v-btn>
    <v-card
      v-if="contact"
      class="mt-3"
      border
      flat
      tile
    >
      <v-card-title class="fs-24 fw-500 mb-2">
        {{ $t('Manage email preferences') }}
      </v-card-title>
      <v-card-subtitle class="fs-16">
        {{ $t('You can opt in or out of receiving specific groups of emails.') }}
      </v-card-subtitle>
      <v-card-text>
        <v-row class="fs-14 fw-600">
          <v-col
            cols="12"
            sm="6"
          >
            {{ $t('Email group') }}
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="12"
            sm="6"
          >
            {{ $t('Receive emails?') }}
          </v-col>
        </v-row>
        <div v-if="$role == 'manager'">
          <v-divider class="my-4" />
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="fs-16 mb-2">
                {{ $t('Availability reminder') }}
              </div>
              <div class="fs-14 c-light-black">
                {{ $t('This is a weekly email reminding you to update your openings.') }}
              </div>
            </v-col>
            <v-col
              class="d-flex justify-end"
              cols="12"
              sm="6"
            >
              <v-switch
                v-model="contact.availability_reminders"
                hide-details
                mandatory
              />
            </v-col>
          </v-row>
        </div>
        <v-divider class="my-4" />
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <div class="fs-16 mb-2">
              {{ $t('Messaging') }}
            </div>
            <div class="fs-14 c-light-black">
              {{
                $t(
                  "Receive notifications when someone messages you. If you opt out, you won't be able to receive these emails.",
                )
              }}
            </div>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="12"
            sm="6"
          >
            <v-switch
              v-model="contact.provider_inquiries"
              hide-details
              mandatory
            />
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <div class="fs-16 mb-2">
              {{ $t('Unsubscribe all') }}
            </div>
            <div class="fs-14 c-light-black">
              {{
                $t(
                  'Unsubscribe from all emails. You will continue to receive login and password reset emails when you request them.',
                )
              }}
            </div>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="12"
            sm="6"
          >
            <v-checkbox
              v-model="contact.unsubscribe_all"
              hide-details
              mandatory
            />
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row dense>
          <v-col cols="12">
            <div class="fs-16">
              {{ $t('Confirm your email address to update preferences:') }}
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="email"
              variant="filled"
              block
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="save"
          :disabled="!email"
          class="w-100pc"
          color="primary"
        >
          <span>{{ $t('Save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  data() {
    return {
      contact: {
        availability_reminders: true,
        provider_inquiries: true,
        unsubscribe_all: false,
      },
      email: null,
    };
  },

  created() {
    const vm = this;
    this.$store.dispatch('identify', {
      success() {
        vm.email = vm.$store.state.profile ? vm.$store.state.profile.email : vm.$route.query.email;
        vm.load();
      },
      failure() {
        vm.email = vm.$route.query.email;
        vm.load();
      },
    });
  },

  methods: {
    load() {
      if (this.email) {
        Api.contact.get(this.email, (resp) => {
          this.contact = resp.data;
        });
      }
    },

    save() {
      Api.contact.update(this.email, this.contact, () => {
        this.$eventBus.$emit('chime', 'Email preferences saved');
      });
    },
  },
};
</script>
