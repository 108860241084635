/* eslint-disable no-param-reassign */
import Api from '@/shared/services/all_bright_finder';

async function getTranslatedText(inputText, i18n) {
  const { fallbackLocale, locale, messages, t } = i18n;

  // return if locale is 'en' or 'en-US' (fallback)
  if (locale === 'en' || locale === fallbackLocale) return inputText;
  // we already have an i18n translation if the 'i18n.messages' either:
  //   1. includes a key (for english) or
  //   2. includes a value (for anything other than english)
  //  ...that matches the input text
  const hasExistingTranslation =
    Object.keys(messages).length > 0 &&
    !!messages[locale] &&
    (Object.keys(messages[locale]).includes(inputText) ||
      Object.values(messages[locale]).includes(inputText));
  if (hasExistingTranslation) return t(inputText);

  // If locale is not english and there is no local translation,
  // fetch translated text from backend
  const translationResp = await Api.translations.translate(inputText, locale);
  return translationResp?.value || inputText; // always fall back to english
}

export { getTranslatedText };
